import objectAssignDeep from 'object-assign-deep';
import devConfig from './aws-exports.dev';
import stagingConfig from './aws-exports.staging';
import prodConfig from './aws-exports.prod';
import {Endpoints} from "./api/lib/endpoints";

let awsExports = {
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

		// REQUIRED - Amazon Cognito Region
		region: 'us-east-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Configuration for cookie storage
		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		/*cookieStorage: {
		  // REQUIRED - Cookie domain (only required if cookieStorage is provided)
		  domain: '.cando.com',
		  // OPTIONAL - Cookie path
		  path: '/',
		  // OPTIONAL - Cookie expiration in days
		  expires: 365,
		  // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
		  // sameSite: "strict" | "lax",
		  sameSite: "strict",
		  // OPTIONAL - Cookie secure flag
		  // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
		  secure: true
		},*/

		// OPTIONAL - customized storage object
		// storage: MyStorage,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',
		authenticationFlowType: 'USER_SRP_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		oauth: {
			redirectSignIn: process.env.REDIRECT_URL,
			redirectSignOut: process.env.REDIRECT_URL,
			responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
		}
	},
	API: {
		endpoints: [
			{
				name: Endpoints.ISCPortalAPI,
				endpoint: "https://iscdev-portal.cando.com"
			},
      {
        name: Endpoints.ISCPortalMock,
        endpoint: "https://virtserver.swaggerhub.com/HansonInc/ISC-Portal-API/1.0.0-beta-007"
      },
      {
        name: Endpoints.ISCPortalLegacy,
        endpoint: "https://iscdev-app.cando.com"
      }
		]
	}
};

switch (process.env.API_ENV) {
  case 'dev':
    objectAssignDeep(awsExports, devConfig);
    break;
  case 'staging':
    objectAssignDeep(awsExports, stagingConfig);
    break;
  case 'prod':
    objectAssignDeep(awsExports, prodConfig);
    break;
}

export default awsExports;
