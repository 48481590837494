import {Endpoints} from "./api/lib/endpoints";

const awsExports = {
	Auth: {
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-1_okHcvMd7y',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '5ep1or82543cahpkvpfttdda15',

		// OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'thespotlightapp.auth.us-east-1.amazoncognito.com',
      scope: ['openid', 'profile', 'email', 'phone', 'https://portal.thespotlightapp.com/api-access']
    }
	},
	API: {
		endpoints: [
      {
        name: Endpoints.ISCPortalAPI,
        endpoint: "https://portal-api.isccameras.com"
      },
      {
        name: Endpoints.ISCPortalLegacy,
        endpoint: "https://iscdev-app.cando.com"
      },
      {
        name: Endpoints.ISCPortalMock,
        endpoint: "https://virtserver.swaggerhub.com/HansonInc/ISC-Portal-API/1.0.0-beta-007"
      },
		]
	}
};

export default awsExports;
