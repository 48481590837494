import {Endpoints} from "./api/lib/endpoints";

const awsExports = {
	Auth: {
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-1_6exSJao3G',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: 'ku5jl6tohjh1js3fsvivou52t',

		// OPTIONAL - Hosted UI configuration
		oauth: {
			domain: 'iscdev.auth.us-east-1.amazoncognito.com',
      scope: ['openid', 'profile', 'email', 'phone', 'https://iscdev.cando.com/api-access']
		}
	},
	API: {
		endpoints: [
      {
        name: Endpoints.ISCPortalAPI,
        endpoint: "https://iscdev-portal.cando.com"
      },
      {
        name: Endpoints.ISCPortalLegacy,
        endpoint: "https://iscdev-app.cando.com"
      },
      {
        name: Endpoints.ISCPortalMock,
        endpoint: "https://virtserver.swaggerhub.com/HansonInc/ISC-Portal-API/1.0.0-beta-007"
      }
		]
	}
};

export default awsExports;
